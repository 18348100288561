@font-face {
  font-family: 'Stretched Ass';
  src: url('/assets/fonts/StretchedAssRegular.woff2') format('woff2'),
      url('/assets/fonts/StretchedAssRegular.woff') format('woff'),
      url('/assets/fonts/StretchedAssRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Stretched Hass';
  src: url('/assets/fonts/StretchedHassRegular.woff2') format('woff2'),
      url('/assets/fonts/StretchedHassRegular.woff') format('woff'),
      url('/assets/fonts/StretchedHassRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'NeueHaasGroteskText Std';
  src: url('/assets/fonts/NHaasGroteskTXStd-56It.woff2') format('woff2'),
      url('/assets/fonts/NHaasGroteskTXStd-56It.woff') format('woff'),
      url('/assets/fonts/NHaasGroteskTXStd-56It.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaasGroteskText Std Md';
  src: url('/assets/fonts/NHaasGroteskTXStd-66MdIt.woff2') format('woff2'),
      url('/assets/fonts/NHaasGroteskTXStd-66MdIt.woff') format('woff'),
      url('/assets/fonts/NHaasGroteskTXStd-66MdIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaasGroteskText Std';
  src: url('/assets/fonts/NHaasGroteskTXStd-55Rg.woff2') format('woff2'),
      url('/assets/fonts/NHaasGroteskTXStd-55Rg.woff') format('woff'),
      url('/assets/fonts/NHaasGroteskTXStd-55Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasGroteskText Std Md';
  src: url('/assets/fonts/NHaasGroteskTXStd-65Md.woff2') format('woff2'),
      url('/assets/fonts/NHaasGroteskTXStd-65Md.woff') format('woff'),
      url('/assets/fonts/NHaasGroteskTXStd-65Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

html {
  --black: #111;
  --grey: #e0e0e0;
  font-size: 90%
}

body {
  font-family: 'Stretched Hass';
  background-color: #e0e0e0;
  color: #111;
  -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

-moz-font-feature-settings:"kern" 1; 
-ms-font-feature-settings:"kern" 1; 
-o-font-feature-settings:"kern" 1; 
-webkit-font-feature-settings:"kern" 1; 
font-feature-settings:"kern" 1;
font-kerning: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width:100%;
  vertical-align: middle;
}

.dib{display:inline-block}

.px2{padding-left:2rem;padding-right:2rem}
.px1{padding-left:1rem;padding-right:1rem}
.py1{padding-top:1rem;padding-bottom:1rem}
.pl1{padding-left:1rem}
.pr1{padding-right:1rem}

.ttu{text-transform:uppercase}
.x{display:flex}
.xw{flex-wrap:wrap}
.xac{align-items:center}
.xjb{justify-content:space-between}
.c12{width:100%}
.c6{width:50%}

.mb1{margin-bottom:1rem}
.mb0-5{margin-bottom:0.5rem;}
.mb2{margin-bottom:2rem}
.pt1{padding-top:1rem}
.pt2{padding-top:2rem}
.pl2{padding-left:4rem}

.xafs{align-items:flex-start}

/*
  Typography
*/

h1,h2,h3,h4,h5{
  font-weight: 500;
}

p {
  font-size: 14px;
  letter-spacing: 0.04em;
}

h1 {
  font-size: 50px;
  line-height:1;
  letter-spacing:-0.0255em;
}
h2{
  font-size: 30px;
  letter-spacing:0.01em;
}

a{
  transition:0.3s ease-out opacity;
}
a:hover {
  opacity:0.4;
}

.psr{position:relative;}
.psa{position:absolute;}

#feed {
  position:relative;
  padding-right:1rem;
  margin-left: 50vw;
}

#landing{
  height:100vh;
  position:fixed;
  top:0;left:0;
  width: 50vw;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
#clients {
  max-width: 550px
}
@media (max-width: 1000px) {
  #landing {
    width:60vw;
  }
  #feed {
    width: 40vw;
    margin-left:60vw;
  }
}
#landing-content {
  min-height:100vh;
}
.xsfe {
  align-self:flex-end;
}

/*
landing 
*/

#five-six-logo {
  width:2.56rem;
}

.project-link {
  width:100%;
}
.project-link.width-50{width: calc(50% - 0.5rem)}
.project{
  border-left:1px solid;
  padding-right:0;
  border-bottom:1px solid;
}
.project figure {
  padding-bottom:56.25%;
  height:0;
  width:100%;
  overflow:hidden;
}
.project figure img {
  height:100%;width:100%;
  object-fit:cover;
  object-position:center;
}

.arrow{
  display: inline-block;
  width: 25px;
  transform:translateY(2px) translateX(-2px);
}
span {
  vertical-align: middle;
}

.dib{display:inline-block}
.vam{vertical-align:middle}

.article {
  position: fixed;
  top: 0;
  right:0;
  height:100vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: calc(100vw - 64px);
  background-color: rgba(255,255,255,0.56);
  backdrop-filter: blur(25px);
  border-left: 1px solid;
}

.back2home {
  position:fixed;
  top:0;left:0;
  height:100vh;
  width: 64px;
}

.loading {
  height:100vh;
}

.article {
  transform:translateX(100vw);
  transition:0.3s ease-out transform;
  will-change:transform;
}
.loading-false .article{
  transform:translateX(0);
}


.smooth-enter .article {
  z-index: 4;
}
.loading-false.smooth-exit .article {
  transform:translateX(100vw);
  z-index:1;
}
strong {
  font-family: 'times';
  font-weight:400;
  line-height:0.8;
}
b,strong{font-weight: 400}

.project-header::after {
  content: ' ';
  width: calc(100% - 2rem);
  left:50%;transform:translateX(-50%);
  position: absolute;
  border-bottom: 1px solid;
}


.my4{
  margin-top:4rem;
  margin-bottom:4rem;
}
.project-text.width-small {max-width: 56rem}
.project-text.positioning-centered{margin-left:auto;margin-right:auto}


.project-text p {
  font-size: 20px;
  margin-bottom:14px;
  line-height:1.4;
  letter-spacing:0.02em;
}

video{
  width: 100%;
  vertical-align: middle;
}

.single-video.width-small {
  width: 70%;
  margin-left:auto;margin-right:auto;
}

.img figure {
  height:0;
}
.img img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  top: 0; left: 0;
  vertical-align: middle;
}

.mt1{margin-top:1rem}

.mxa{margin-left:auto;margin-right:auto;}
.single-image.width-small {
  width:70%;
}


.width-small.double-image {
  width: 70%;
  max-width: 60rem;
}

.double-image .immmage {
  width: calc(50%-0.5rem);
}
.double-image {
  max-width: 86rem
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}


@media (max-width: 700px) {
  h1 {font-size: 40px}
  h2{font-size: 24px}
  p{font-size: 12px}
  
  #landing {
    width:100vw;
    position:relative;
    min-height:auto;
    height:auto;
  }
  #landing-content{
    min-height:unset;
    padding-top:3rem;
  }
  #feed {
    width: 100vw;
    padding: 0 1rem;
    margin-left:0vw;
  }

  header {
    position:fixed;
    top:0;left:0;
    z-index:3;
    padding:1rem;
    background-color:var(--grey);
    align-items: center;
  }

  #AR-parent {
    z-index: 10;
  }

  .article {
    height: calc(100vh - 3.7rem);
    width: 100vw;
    top: 3.7rem;
    border-left:0;
    border-top:1px solid;
    transform:translateY(100vh);
  }

  .loading-false .article{
    transform:translateY(0);
  }
  
  .smooth-enter .article {
    z-index: 4;
  }
  .loading-false.smooth-exit .article {
    transform:translateY(100vh);
    z-index:1;
  }
  .back2home {
    position:fixed;
    top:0;left:0;
    height:3.7rem;
    z-index:9999;
    width: 100vw;
  }
  .project-link, .project-link.width-50 {
    width: 100%;
  }
  
}


#newsletter {
  position:fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:var(--grey);
  border:1px solid black;
  z-index:41;
}

#back2home2{
  position:fixed;
  top:0;left:0;
  height:100vh;
  display:block;
  z-index:40;
  width:100vw;
  backdrop-filter: blur(29px);
}
#back2home2:hover{
  opacity: 1;
}

#newsletter input {
  padding: 1rem;
  text-align:center;
}
#newsletter input:focus {
  outline:none;
  border:none;
}

#nl {
  opacity:0;
  transition:0.3s ease-out opacity;
}

#nl.loading-true {
  opacity: 0;
}

#nl.smooth-enter, #nl.smooth-enter-done {
  opacity: 1;
}